export default [
  {
    key: "search",
    label: "field.search",
    type: "text",
  },
  {
    key: "isFeature",
    label: "field.feature",
    rules: "required",
    type: "radio",
    cast: "boolean",
    options: [
      { text: "field.feature", value: "1" },
      { text: "field.notFeature", value: "0" },
    ],
    lg: "auto",
  },
];
